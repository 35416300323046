@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fascinate&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap");

@layer base {
  :root {
    --background: 0 0% 100%;
    --background-gradient: linear-gradient(
      135deg,
      #ffffff 0%,
      #e0ecf8 50%,
      #ffffff 100%
    );
    --foreground: 224 69.4% 14%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 224, 10%, 50%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 262.1 83.3% 57.8%;
    --primary-foreground: 210 20% 98%;
    --secondary: 240, 50%, 70%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 262.1 83.3% 57.8%;
    --radius: 0.5rem;
    --toggle: 262.1 83.3% 57.8%;
    --middle: 252.1 83.3% 50.8%;
    --name: 252.1, 83.3%, 30%;
    --button-gradient: linear-gradient(
      135deg,
      rgba(0, 51, 102, 0.8),
      rgba(102, 179, 255, 0.8)
    );
    --button-gradient-hover: linear-gradient(
      135deg,
      rgba(224, 179, 255, 1),
      rgba(74, 26, 97, 1)
    );
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --background-gradient: linear-gradient(135deg, #000000, #111139, #000000);
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 10% 85%;
    --popover-foreground: 210 20% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    --secondary: 210, 60%, 80%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 263.4 70% 50.4%;
    --toggle: 262.1 83.3% 70.8%;
    --middle: 260.1 83.3% 20.8%;
    --name: 270, 60%, 85%;
    --button-gradient: linear-gradient(
      135deg,
      rgba(211, 166, 241, 0.8),
      rgba(106, 42, 137, 0.8)
    );
    --button-gradient-hover: linear-gradient(
      135deg,
      rgba(0, 51, 102, 0.9),
      rgba(102, 179, 255, 0.9)
    );
  }
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
  border-bottom: 1px solid hsl(var(--border));
  font-family: "Noto Serif", sans-serif;
  z-index: 10;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
  margin-left: auto;
}

nav ul li a {
  text-decoration: none;
  color: hsl(var(--foreground));
  font-size: 1.2rem;
  transition: color 0.3s ease;
  display: flex;
  /* padding: 10px 15px; */
}

nav ul li a:hover {
  color: hsl(var(--primary));
}

nav ul li a.active {
  color: lightskyblue;
  font-weight: bold;
  border-bottom: 2px solid lightskyblue;
}

.accordion {
  max-width: 100%;
  margin: 20px auto;
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  overflow: hidden;
  font-family: "Noto Serif", sans-serif;
}

.accordion-item {
  border-top: 1px solid hsl(var(--background));
  font-family: "Noto Serif", sans-serif;
  padding: 10px 0px;
}

.accordion-item:first-child {
  border-top: none;
  font-family: "Noto Serif", sans-serif;
}

.accordion-header {
  padding: 10px;
  background: hsl(var(--foreground));
  color: hsl(var(--background));
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background 0.3s ease;
  font-family: "Noto Serif", sans-serif;
  border-radius: 100px;
  box-shadow: 0 4px 8px rgba(132, 171, 185, 0.3),
    0 6px 12px rgba(177, 216, 230, 0.5);
}

.accordion-header .icon {
  margin-left: auto;
  text-align: right;
  margin-right: 20px;
}
.gradient_background {
  background: var(--background-gradient);
  transition: background 0.3s ease-in-out;
}

.accordion-header:hover {
  background: hsl(var(--primary));
}

.accordion-header.active .icon {
  transform: rotate(180deg);
}

.accordion-header.active {
  background-color: hsl(var(--secondary));
  color: hsl(var(--background));
}

.accordion-content {
  padding: 10px;
  display: none;
  top: 100%;
  width: 95%;
  justify-self: center;
  background: hsl(var(--foreground));
  transition: max-height 0.3s ease, opacity 0.3s ease;
  border-radius: 0px 0px 20px 20px;
  color: hsl(var(--background));
}

.accordion-content.open {
  display: block;
}

.section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: hsl(var(--foreground));
  align-self: center;
  border-bottom: 2px solid hsl(var(--foreground));
  padding-bottom: 10px;
  text-transform: capitalize;
  font-family: "Noto Serif", sans-serif;
}

section {
  padding: 20px;

  padding-bottom: 35px;
}

.p-primary {
  display: flex;
  justify-content: center;
  font-size: 50px;
  color: hsl(var(--foreground));
  font-weight: bold;
  margin: 15px 0;
  font-family: "Noto Serif", sans-serif;
  position: absolute;
  z-index: 10;
  top: 7%;
  left: 10%;
}

.p-secondary {
  display: flex;
  justify-content: center;
  font-size: 35px;
  color: hsl(var(--middle));
  /* font-style: italic; */
  /* margin: 10px 0; */
  font-family: "Noto Serif", sans-serif;
  top: 20%;
  left: 30%;
  z-index: 10;
  position: absolute;
}

.p-name {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 10;
  font-size: 150px;
  color: hsl(var(--name));
  margin: 10px 0;

  font-family: "Sacramento", sans-serif;
  font-style: cursive;
  top: 33%;
  left: 35%;
  font-weight: 500;
}

.p-title {
  display: flex;
  justify-content: center;
  font-size: 35px;
  color: hsl(var(--foreground));
  font-weight: bold;
  margin: 15px 0;
  font-family: "Noto Serif", sans-serif;
  z-index: 10;
}

.p-general {
  font-size: 20px;
  display: flex;
  justify-content: center;
  color: hsl(var(--foreground));
  font-family: "Noto Serif", sans-serif;
  z-index: 0;
}

.card-container {
  display: flex;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 90px;
  /* max-width: 1400px; */
  width: 100%;
  box-sizing: border-box;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* padding: 20px;
  padding-bottom: 200px;  */
  background-color: hsl(var(--background));
  border-radius: 15px;
  border: 2px solid hsl(var(--foreground));
  color: hsl(var(--foreground));
  font-family: "Noto Serif", sans-serif;
  box-sizing: border-box;
  flex: 1 1 350px;
  max-width: 350px;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(173, 216, 230, 0.3),
    0 6px 12px rgba(173, 216, 230, 0.5);
}

.card.active {
  display: block;
}

.card p {
  font-size: 15px;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}

.buttons {
  text-align: center;
}
/* margin: 20px 0;
} */

.buttons button {
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  cursor: pointer;
}

.buttons button.active {
  background-color: #007bff;
  color: white;
  border: none;
}

.card-buttons a {
  display: inline-block;
  margin: 5px;
  padding: 10px 40px;
  background: var(--button-gradient);
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  transition: background 0.3s ease;
  border-radius: 40px;
}

.card-buttons a:hover {
  background: var(--button-gradient-hover);
}

.card-buttons {
  display: "inline-block";
  margin: -10px;
  padding: 10px 15px;
  /* background: "pink"; */
  color: "#fff";
  font-size: 14px;
  transition: background 0.3s ease;
}

.carousel {
  position: relative;
  max-width: 100%;
  margin: 50px auto;
  overflow: hidden;
  padding: 0 40px;
}

.carousel-track-container {
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 40 60px;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  background-color: hsl(var(backgruond));
}

.carousel-card {
  flex: 0 0 calc(90% - 20px);
  margin: 10px;
  background-color: hsl(var(--foreground));
  border-radius: 10px;
  border: 1px solid hsl(var(--background));
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  font-family: "Noto Serif", sans-serif;
}

.carousel-card:hover {
  transform: scale(1.05);
}

.carousel-card h3 {
  margin: 0;
  color: hsl(var(--accent));
}

.carousel-card p {
  color: hsl(var(--background));
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;
}

.carousel-btn.prev {
  left: 10px;
}

.carousel-btn.next {
  right: 10px;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.toggle-container {
  width: 70px;
  height: 25px;
  background: hsl(var(--background-gradient));
  border-radius: 50px;
  border: 2px solid #3099b5;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  transition: background 0.3s ease, border-color 0.3s ease,
    justify-content 0.3s ease;
  justify-content: space-between;
}

.toggle-container.toggled {
  background: hsl(var(--background-gradient));
  border-color: #0a0a23;
  flex-direction: row-reverse;
  border: 2px solid #d4f1f9;
}

.background-icon {
  font-size: 16px;
  color: inherit;
  opacity: 0.8;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.toggle-circle {
  width: 25px;
  height: 25px;
  background: hsl(var(--popover));
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.toggle-container.toggled .toggle-circle {
  border-color: #0a0a23;
}

.icon {
  color: inherit;
}

.resume {
  display: inline-block;
  border: 2px solid var(--secondary);
  border-radius: 8px;
  padding: 5px 10px;
  background-color: hsl(var(--secondary));
  color: hsl(var(--foreground));
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  z-index: 100;
}

.resume:hover {
  color: white;
  background-color: hsl(var(--muted));
}

.resume-page {
  text-align: center;
  font-family: "Noto Serif", sans-serif;
  padding: 20px;
  z-index: 100;
  /* height: 80vh; */
}

.resume-viewer {
  margin: 20px auto;
  max-width: 800px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
}

.resume-frame {
  width: 100%;
  height: 600px;
}

.resume-download {
  margin-top: 20px;
}

.download-button {
  display: inline-block;
  background-color: hsl(var(--secondary));
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  font-family: "Noto Serif", sans-serif;
}

.download-button:hover {
  background-color: hsl(var(--primary));
}

.profile-container {
  flex-shrink: 0;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  border: 15px solid hsl(var(--secondary));
  border-radius: 50%;
  overflow: hidden;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  display: inline-block;
  margin: 0px 10px;
  text-decoration: none;
  color: hsl(var(--foreground));
  font-size: 1.2em;
  transition: color 0.3s;
  margin-left: 30px;
}
.social-links a:hover {
  color: #005bb5;
}

.big-container {
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-svg {
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 0;
  position: relative;
}

.foreground-text {
  position: relative;
  z-index: 1;
  font-size: 24px;
  color: black;
}
.custom-shape-divider-bottom-1733625615 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1733625615 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1733625615 .shape-fill {
  fill: pink;
}

.home-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 50px;
}

.timeline-container {
  display: flex;
  justify-content: center;
  /* padding: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  overflow-x: auto;
  margin: 0 auto;
}

.timeline {
  display: flex;
  position: relative;
  gap: 50px;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  padding: 10px;
  background-color: hsl(var(--card));
  border: 1px solid hsl(var(--secondary));
  border-radius: 8px;
  box-shadow: 0 2px 5px hsl(var(--foreground));
  position: relative;
  flex-basis: 200px; /* Set a consistent width for items */
  flex-grow: 1; /* Allow items to grow if needed */
  flex-shrink: 1; /* Allow items to shrink if necessary */
  max-width: 250px; /* Optional: Limit the maximum width */
  min-width: 150px; /* Optional: Set a minimum width */
}

.timeline-content {
  text-align: center;
}

.timeline-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  color: hsl(var(--name));
  font-family: "Noto Serif", sans-serif;
}

.timeline-degree {
  font-size: 18px;
  color: hsl(var(--foreground));
  margin-bottom: 5px;
  font-family: "Noto Serif", sans-serif;
}

.timeline-year {
  font-size: 18px;
  font-weight: bold;
  color: hsl(var(--muted-foreground));
  margin-bottom: 10px;
  font-family: "Noto Serif", sans-serif;
}

.timeline-description {
  font-size: 16px;
  color: hsl(var(--muted-foreground));
  line-height: 1.4;
  font-family: "Noto Serif", sans-serif;
}

.timeline-connector {
  position: absolute;
  top: 50%;
  left: 100%;
  width: 180px;
  height: 5px;
  background-color: hsl(var(--secondary));
  margin-top: -1px;
}

.timeline-cover-image {
  width: 100%;
  height: 230px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
}

.timeline-cover-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.timeline-item:hover {
  transform: scale(1.05);
}

@keyframes sparkle {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.sparkle {
  animation: sparkle 1s ease-out forwards;
  color: #ffd700;
}
